<template>
  <div v-if="tbDialog">
    <el-dialog :visible.sync="dialog" width="820px" center @close="closedialog">
      <template slot="title">
        <div class="title_div">
          <img class="logo" src="@/assets/logosss.png" />
          <div class="words">{{ !isLogin ? '账号订购-淘宝版' : '账号续费-淘宝版' }}</div>
          <img class="bgclogo" src="@/assets/bgclogo.png" />
        </div>
      </template>
      <div class="body" v-if="flag == 0" v-loading="isLoading">
        <div class="left">
          <div class="left-title">
            <img src="@/assets/towes.png" class="title-logo" />
            <div class="title-words">专业版</div>
          </div>
          <div class="left-main">
            <div class="left-items">
              <img src="@/assets/checks.png" class="left-imgs" />
              <div class="left-words">单商品自动弹品</div>
            </div>
            <div class="left-items">
              <img src="@/assets/checks.png" class="left-imgs" />
              <div class="left-words">多商品循环弹品</div>
            </div>
            <div class="left-items">
              <img src="@/assets/checks.png" class="left-imgs" />
              <div class="left-words">定时自动发评</div>
            </div>
            <div class="left-items">
              <img src="@/assets/checks.png" class="left-imgs" />
              <div class="left-words">关键词监测自动回评</div>
            </div>
            <div class="left-items">
              <img src="@/assets/checks.png" class="left-imgs" />
              <div class="left-words">直播间评论监测分析</div>
            </div>
            <div class="left-items">
              <img src="@/assets/checks.png" class="left-imgs" />
              <div class="left-words">直播间实时数据大屏</div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="right-top">
            <div class="right-title">
              <div>版本</div>
              <div
                v-if="isLogin"
                style="font-size: 14px; color: #5247ff; margin-top: 6px; cursor: pointer"
                @click="openBuying"
              >
                我的订单
              </div>
            </div>
            <div class="right-buttons">
              <div
                class="right-button"
                :class="versionChoose == 0 ? 'select' : ''"
                @click="versionChoose = 0"
              >
                试用版
                <div class="click-button" v-if="versionChoose == 0">
                  <img src="@/assets/gouwhite.png" alt="" style="width: 14px" />
                </div>
              </div>
              <div
                class="right-button"
                :class="versionChoose == 1 ? 'select' : ''"
                @click="versionChoose = 1"
              >
                专业版
                <div class="click-button" v-if="versionChoose == 1">
                  <img src="@/assets/gouwhite.png" alt="" style="width: 14px" />
                </div>
              </div>
            </div>
          </div>
          <div class="right-top">
            <div class="right-title">购买时长</div>
            <div class="right-chooses" v-if="versionChoose == 0">
              <div
                class="right-item"
                :class="chooseItem == 0 ? 'item-select' : ''"
                @click="chooseItem = 0"
              >
                <div class="item-year">7天</div>
                <div class="item-price">
                  <span style="font-size: 13px">￥</span>
                  0
                </div>
              </div>
            </div>
            <div class="right-chooses" v-if="versionChoose == 1">
              <div
                class="right-item"
                :class="chooseItem == 1 ? 'item-select' : ''"
                @click="chooseItem = 1"
              >
                <div class="item-year">1年</div>
                <div class="item-price">
                  <span style="font-size: 13px">￥</span>
                  {{
                    skuPrice[4].discounts
                      ? skuPrice[4].discountsPrice / 100
                      : skuPrice[4].salePrice / 100
                  }}
                </div>
                <div class="item-additional">低至2.5元/日</div>
                <div class="item-sup">性价比首选</div>
              </div>
              <div
                class="right-item"
                :class="chooseItem == 2 ? 'item-select' : ''"
                @click="chooseItem = 2"
              >
                <div class="item-year">6个月</div>
                <div class="item-price">
                  <span style="font-size: 13px">￥</span>
                  {{
                    skuPrice[3].discounts
                      ? skuPrice[3].discountsPrice / 100
                      : skuPrice[3].salePrice / 100
                  }}
                </div>
                <div class="item-additional">已购3000+</div>
                <div class="item-sup">80%用户选择</div>
              </div>
              <div
                class="right-item"
                :class="chooseItem == 3 ? 'item-select' : ''"
                @click="chooseItem = 3"
              >
                <div class="item-year">3个月</div>
                <div class="item-price">
                  <span style="font-size: 13px">￥</span>
                  {{
                    skuPrice[2].discounts
                      ? skuPrice[2].discountsPrice / 100
                      : skuPrice[2].salePrice / 100
                  }}
                </div>
              </div>
              <div
                class="right-item"
                :class="chooseItem == 4 ? 'item-select' : ''"
                @click="chooseItem = 4"
              >
                <div class="item-year">1个月</div>
                <div class="item-price">
                  <span style="font-size: 13px">￥</span>
                  {{
                    skuPrice[1].discounts
                      ? skuPrice[1].discountsPrice / 100
                      : skuPrice[1].salePrice / 100
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="right-middle" v-if="!isLogin">
            <div style="margin-right: 30px">
              <div class="item-title">登录手机号</div>
              <el-input v-model="phone" style="width: 200px" placeholder="请输入手机号" />
            </div>
            <div>
              <div class="item-title">验证码</div>
              <el-input
                v-model="checkCode"
                style="width: 260px"
                placeholder="请输入验证码"
                maxlength="6"
              >
                <template slot="append">
                  <div style="cursor: pointer" @click="getCheckCodes">
                    {{ time }}
                  </div>
                </template>
              </el-input>
            </div>
          </div>
          <div class="right-bottom">
            <div class="right-title">支付方式</div>
            <div class="pay-list">
              <div class="pay-item" @click="toPay(1)">
                <img src="@/assets/wxLogos.png" class="pay-logo" />
                <div>微信扫码支付</div>
              </div>
              <div class="pay-item" @click="toPay(2)">
                <img src="@/assets/zfbLogos.png" class="pay-logo" />
                <div>支付宝支付</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="body2" v-if="flag == 1">
        <div class="boxss">
          <img class="body-img" src="@/assets/greenGou.png" />
          <div class="paying">已完成支付</div>
          <div class="refresh" @click="refresh" v-if="isLogin">已支付，点击刷新</div>
          <div class="refresh" @click="toLogin" v-else-if="!isLogin && versionChoose">
            已支付，点击去登录
          </div>
<!--          <div class="refresh2" @click="toDownLoad" v-else>点击下载客户端开始使用</div>-->
          <div class="refresh2" @click="toLogin" v-else>点击去登录</div>
        </div>
        <div style="font-weight: 400; font-size: 14px; color: #606266">
          未完成支付？
          <span style="color: #6647ff; cursor: pointer" @click="init">重新选择</span>
        </div>
      </div>
    </el-dialog>

    <!-- 微信支付弹窗 -->
    <el-dialog class="payDialog" :visible.sync="wxDialogVisible" width="360px" center top="30vh">
      <div class="wx-pay-dialog">
        <div class="title">微信支付</div>
        <div class="tip">请使用微信-扫一扫-进行支付</div>
        <div class="qrcode">
          <div
            id="qrcodes"
            ref="qrCodeContainer"
            style="width: 160px; height: 160px; margin-bottom: 20px"
          ></div>
        </div>
      </div>
    </el-dialog>

    <el-dialog class="payDialog" :visible.sync="buyingDialog" width="800px" center top="30vh">
      <div class="wx-pay-dialog">
        <div class="title" style="margin-bottom: 16px">我的订单</div>
        <el-table :data="tableData" style="width: 100%" max-height="240">
          <el-table-column label="订单编号" width="300">
            <template slot-scope="scope">
              <span>{{ scope.row.orderNo }}</span>
            </template>
          </el-table-column>
          <el-table-column label="订单信息">
            <template slot-scope="scope">
              <span>
                {{ scope.row.versionTitle }}-{{
                  scope.row.years
                    ? `${scope.row.years}年`
                    : scope.row.months
                    ? `${scope.row.months}月`
                    : `${scope.row.days}天`
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="付款金额">
            <template slot-scope="scope">
              <span>￥{{ (scope.row.totalAmount / 100).toFixed(2) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="支付时间" width="200">
            <template slot-scope="scope">
              <span>{{ scope.row.gmtPayment }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import user from '@/api/user'
import { mapState } from 'vuex'
import ajax from '@/libs/request'
import QRCode from 'qrcodejs2'

export default {
  name: 'buyLocal',
  props: {
    isLogin: {
      type: Boolean,
      default: true
    },
    isClient: {
      type: Boolean,
      default: false
    },
    clientId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialog: false,
      chooseItem: 1,
      flag: 0,
      skuPrice: [],
      wxDialogVisible: false,
      wxQrCode: '直播中控宝',
      timer: null,
      isLoading: false,
      count: 120,
      versionChoose: 0,
      phone: '',
      checkCode: '',
      time: '发送验证码',
      codeTimer: null,
      buyingDialog: false,
      tableData: []
    }
  },
  computed: {
    ...mapState(['tbDialog'])
  },
  methods: {
    toDownLoad() {
      this.$emit('toDownloadClient')
      this.dialog = false
      this.$store.commit('setTbDialog', false)
    },
    async openBuying() {
      const res = await user.getOrderHistory({
        platformType: 6
      })
      if (res.code == 0) {
        this.tableData = res.data
        this.buyingDialog = true
      } else {
        this.$message.error(res.msg)
      }
    },
    toLogin() {
      if (this.isClient) {
        this.$emit('toLogin', 2)
      } else {
        this.$router.push('/login')
      }
      this.dialog = false
      this.$store.commit('setTbDialog', false)
    },
    refresh() {
      setTimeout(() => {
        location.reload()
      }, 1000)
    },
    closedialog() {
      if (this.flag == 1) {
        this.refresh()
      } else {
        this.dialog = false
        this.$store.commit('setTbDialog', false)
      }
    },
    init() {
      this.getSku()
      this.flag = 0
      this.versionChoose = 0
      this.chooseItem = 0
      this.isLoading = false
      this.count = 120
      clearInterval(this.timer)
      clearInterval(this.codeTimer)
      this.timer = null
      this.codeTimer = null
      this.phone = ''
      this.checkCode = ''
      this.time = '发送验证码'
    },
    async getCheckCodes() {
      if (this.time != '发送验证码') return
      if (!this.phone) {
        return this.$message.warning('手机号不能为空')
      }
      const phoneNumberPattern = /^1\d{10}$/
      if (!phoneNumberPattern.test(this.phone)) {
        return this.$message.warning('请输入正确的手机号码')
      }
      const res = await user.sendSixCode({
        phone: this.phone
      })
      if (res.code == 0) {
        this.time = 60
        this.codeTimer = setInterval(() => {
          if (this.time == 1) {
            clearInterval(this.codeTimer)
            this.time = '发送验证码'
          } else {
            this.time--
            console.log(this.time)
          }
        }, 1000)
      }
    },
    async getSku() {
      const res = await user.getDarenSkuInfo({
        platformType: 6
      })
      if (res.code == 0) {
        this.skuPrice = res.data.skuVoList.filter(ele => ele.id != 1)
      }
    },
    startCount(val) {
      //进入缓存状态，判断成功或失败
      this.isLoading = true
      clearInterval(this.timer)
      this.timer = setInterval(async () => {
        if (this.count == 0) {
          //时间耗尽
          clearInterval(this.timer)
          this.isLoading = false
        } else {
          const res = await user.getDarenOrderStatus({ orderNo: val, tradeNo: '' })
          if (res.code == 0) {
            if (res.data.payStatus == 1) {
              //支付成功
              clearInterval(this.timer)
              this.count = 120
              this.isLoading = false
              this.flag = 1
              this.wxDialogVisible = false
              //发order请求
              if (localStorage.getItem('pluginUUid')) {
                user.createAdLog({
                  adUid: localStorage.getItem('pluginUUid'),
                  operationType: 6,
                  sourceType: 1,
                  orderId: res.data.orderNo
                })
              }
              if (this.isClient) {
                user.createAdLog({
                  adUid: this.clientId,
                  clientId: this.clientId,
                  operationType: 6,
                  sourceType: 3,
                  orderId: res.data.orderNo
                })
              }
            } else if (res.data.payStatus == 3) {
              //支付失败
              clearInterval(this.timer)
              this.count = 120
              this.isLoading = false
              this.wxDialogVisible = false
              this.$message.warning(res.msg)
            } else {
              this.count--
            }
          } else {
            //请求出错
            clearInterval(this.timer)
            this.isLoading = false
            this.wxDialogVisible = false
            this.$message.warning(res.msg)
            this.count = 120
          }
        }
      }, 2000)
    },
    async toPay(type) {
      if (!this.isLogin) {
        if (!this.phone) {
          return this.$message.warning('请输入手机号')
        }
        if (!this.checkCode) {
          return this.$message.warning('请输入验证码')
        }
      }
      let cycleId = 0
      let skuId = 0
      //做判断
      if (this.chooseItem == 0) {
        //七天版
        cycleId = 1
        skuId = 22
      } else if (this.chooseItem == 1) {
        //一年
        cycleId = 5
        skuId = 26
      } else if (this.chooseItem == 2) {
        //半年
        cycleId = 4
        skuId = 25
      } else if (this.chooseItem == 3) {
        //季
        cycleId = 3
        skuId = 24
      } else {
        //一个月
        cycleId = 2
        skuId = 23
      }
      let params = {
        cycleId: cycleId,
        payType: type == 2 ? 1 : 2,
        skuId: skuId,
        versionId: this.versionChoose ? 2 : 1,
        platformType: 6
      }
      if (!this.isLogin) {
        params.phone = this.phone
        params.verifyCode = this.checkCode
      }
      //创建订单
      const res = await user.create(params)
      if (res.code == 0) {
        //试用版
        if (!this.versionChoose) {
          this.startCount(res.data.orderNo)
        } else {
          //专业版
          this.toReallyPay(res.data, type)
        }
      } else {
        return this.$message.warning(res.msg)
      }
    },
    toReallyPay(val, type) {
      if (type == 2) {
        //支付宝支付
        const passbackParams = {
          orderNo: val.orderNo,
          omgOrderNo: val.orderNo,
          serverNo: 6
        }
        const afterParams = encodeURIComponent(JSON.stringify(passbackParams))
        const url = `${val.payUrl}?totalAmount=${val.totalAmountYuan}&subject=${encodeURIComponent(
          val.subject
        )}&passbackParams=${afterParams}`
        if (this.isClient) {
          const data = {
            payUrl: url,
            type: 'send',
            operate: 'aliPay'
          }
          window.top.postMessage(data)
        } else {
          window.open(url)
        }
        this.startCount(val.orderNo)
      } else {
        const passbackParams = {
          orderNo: val.orderNo,
          omgOrderNo: val.orderNo,
          serverNo: 6
        }
        const afterParams = JSON.stringify(passbackParams)
        const getData = () => {
          return ajax.get(val.payUrl, {
            params: {
              total_fee: val.totalAmount,
              order_no: val.orderNo,
              title: val.subject,
              attach: afterParams
            }
          })
        }
        getData().then(res2 => {
          this.wxDialogVisible = true
          setTimeout(() => {
            document.getElementById('qrcodes').innerHTML = ''
            new QRCode('qrcodes', {
              width: 160, // 二维码宽度
              height: 160, // 二维码高度
              text: res2.data, // 浏览器地址url
              colorDark: '#000000',
              colorLight: '#ffffff',
              correctLevel: QRCode.CorrectLevel.H
            })
            this.startCount(val.orderNo)
          }, 20)
        })
      }
    }
  },
  watch: {
    tbDialog: {
      handler: function (val) {
        this.dialog = val
        if (this.dialog) {
          this.init()
        } else {
          clearInterval(this.timer)
          clearInterval(this.codeTimer)
        }
      },
      immediate: true
    },
    dialog: {
      handler: function (val) {
        if (!val) {
          clearInterval(this.timer)
        }
      },
      immediate: true
    },
    versionChoose: {
      handler: function (val) {
        if (val) {
          this.chooseItem = 1
        } else {
          this.chooseItem = 0
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep {
  .el-dialog__header {
    padding: 0 !important;
  }
}

.title_div {
  width: 100%;
  height: 80px;
  background: linear-gradient(135deg, #9f7dff 0%, #6647ff 100%);
  display: flex;
  align-items: center;
  padding-left: 16px;
  box-sizing: border-box;
  position: relative;

  .logo {
    width: 48px;
    height: 48px;
  }

  .words {
    margin-left: 12px;
    font-weight: 500;
    font-size: 18px;
    color: #ffffff;
  }

  .bgclogo {
    position: absolute;
    width: 200px;
    height: 80px;
    right: 67px;
    top: 0;
  }
}

::v-deep .el-dialog__close::before {
  color: black;
}

.body {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
}

.body2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 70px;

  .body-img {
    width: 80px;
    height: 80px;
  }

  .boxss {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .paying {
    width: 90px;
    height: 26px;
    font-weight: 500;
    font-size: 18px;
    color: #303133;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .refresh {
    width: 144px;
    height: 32px;
    background: #6647ff;
    border-radius: 2px;
    color: white;
    line-height: 32px;
    text-align: center;
    margin-bottom: 86px;
    cursor: pointer;
  }
}

::v-deep .el-dialog__body {
  background: linear-gradient(135deg, #fbfaff 0%, #f1efff 100%);
  border-radius: 8px;
  box-sizing: border-box;
}

.left {
  width: 160px;
  height: 356px;

  .left-title {
    width: 80px;
    height: 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .title-logo {
      width: 20px;
      height: 20px;
    }

    .title-words {
      /* 创建渐变 */
      background: linear-gradient(45deg, #9f7dff 0%, #6647ff 100%);
      /* 设置背景为渐变，并添加背景文字 */
      -webkit-background-clip: text;
      background-clip: text;
      /* 设置文字颜色为透明，使得渐变背景能够显示出来 */
      color: transparent;
      font-weight: 500;
      font-size: 18px;
    }
  }

  .left-main {
    height: 260px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .left-items {
      height: 20px;
      display: flex;
      align-items: center;
      margin-bottom: 7px;

      .left-imgs {
        width: 16px;
        height: 16px;
        margin-right: 7px;
      }

      .left-words {
        font-size: 14px;
        color: #606266;
      }
    }
  }
}

.right {
  width: 558px;

  .right-title {
    font-weight: 500;
    font-size: 18px;
    color: #303133;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }

  .right-chooses {
    width: 558px;
    height: 152px;
    display: flex;
    justify-content: space-between;
    padding-top: 12px;

    .right-item:hover {
      background: linear-gradient(135deg, #ffffff 0%, #eae4ff 100%);
      box-shadow: 0px 2px 12px 0px rgba(54, 55, 59, 0.1);
      border-radius: 8px;
      border: 2px solid #6647ff;
      box-sizing: border-box;
    }

    .item-select {
      background: linear-gradient(135deg, #ffffff 0%, #eae4ff 100%);
      box-shadow: 0px 2px 12px 0px rgba(54, 55, 59, 0.1);
      border-radius: 8px;
      border: 2px solid #6647ff;
      box-sizing: border-box;
    }

    .right-item {
      width: 130px;
      height: 140px;
      background: #ffffff;
      border-radius: 8px;
      padding-left: 14px;
      box-sizing: border-box;
      position: relative;
      cursor: pointer;

      .item-year {
        margin-top: 24px;
        font-size: 16px;
        color: #1d2129;
        font-weight: 500;
      }

      .item-price {
        color: #6647ff;
        font-size: 24px;
        margin-top: 8px;
        margin-bottom: 12px;
      }

      .item-additional {
        border-radius: 4px;
        border: 1px solid #6647ff;
        padding: 1px 8px;
        width: 62px;
        font-weight: 500;
        font-size: 12px;
        color: #6647ff;
        text-wrap: nowrap;
      }

      .item-sup {
        position: absolute;
        background: linear-gradient(135deg, #ff8f8f 0%, #ff4444 100%);
        border-radius: 12px 4px 12px 0px;
        padding: 3px 12px;
        left: 0;
        top: -12px;
        color: #ffffff;
        font-size: 12px;
      }
    }
  }

  .right-bottom {
    margin-top: 5px;

    .pay-list {
      display: flex;
      justify-content: space-between;

      .pay-item {
        width: 273px;
        height: 84px;
        background: #ffffff;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 18px;
        color: #1d2129;
        cursor: pointer;
        transition: box-shadow 1s, border 1s;
        /* 定义过渡属性和持续时间 */

        .pay-logo {
          width: 36px;
          height: 36px;
          margin-right: 16px;
        }
      }
    }
  }
}

.pay-item:hover {
  box-shadow: 0px 2px 12px 0px rgba(54, 55, 59, 0.1);
}

.wx-pay-dialog {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 34px;

  .title {
    position: relative;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    color: black;
  }

  .tip {
    position: relative;
    line-height: 36px;
  }
}

.right {
  width: 558px;

  .right-title {
    font-weight: 500;
    font-size: 18px;
    color: #303133;
    margin-bottom: 20px;
  }

  .right-buttons {
    display: flex;
    margin-bottom: 28px;
    .right-button {
      cursor: pointer;
      margin-right: 20px;
      background-color: pink;
      width: 120px;
      height: 38px;
      background: #ffffff;
      border-radius: 4px;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .click-button {
        width: 20px;
        height: 20px;
        background: #6647ff;
        border-radius: 10px 0px 2px 0px;
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .select {
      border: 2px solid #6647ff;
    }
  }

  .right-chooses {
    width: 558px;
    height: 152px;
    display: flex;
    justify-content: space-between;
    padding-top: 12px;

    .right-item:hover {
      background: linear-gradient(135deg, #ffffff 0%, #eae4ff 100%);
      box-shadow: 0px 2px 12px 0px rgba(54, 55, 59, 0.1);
      border-radius: 8px;
      border: 2px solid #6647ff;
      box-sizing: border-box;
    }

    .item-select {
      background: linear-gradient(135deg, #ffffff 0%, #eae4ff 100%);
      box-shadow: 0px 2px 12px 0px rgba(54, 55, 59, 0.1);
      border-radius: 8px;
      border: 2px solid #6647ff;
      box-sizing: border-box;
    }

    .right-item {
      width: 130px;
      height: 140px;
      background: #ffffff;
      border-radius: 8px;
      padding-left: 14px;
      box-sizing: border-box;
      position: relative;
      cursor: pointer;

      .item-year {
        margin-top: 24px;
        font-size: 16px;
        color: #1d2129;
        font-weight: 500;
      }

      .item-price {
        color: #6647ff;
        font-size: 24px;
        margin-top: 8px;
        margin-bottom: 12px;
      }

      .item-additional {
        border-radius: 4px;
        border: 1px solid #6647ff;
        padding: 1px 8px;
        width: 62px;
        font-weight: 500;
        font-size: 12px;
        color: #6647ff;
        text-wrap: nowrap;
      }

      .item-sup {
        position: absolute;
        background: linear-gradient(135deg, #ff8f8f 0%, #ff4444 100%);
        border-radius: 12px 4px 12px 0px;
        padding: 3px 12px;
        left: 0;
        top: -12px;
        color: #ffffff;
        font-size: 12px;
      }
    }
  }

  .right-bottom {
    margin-top: 15px;

    .pay-list {
      display: flex;
      justify-content: space-between;

      .pay-item {
        width: 273px;
        height: 84px;
        background: #ffffff;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 18px;
        color: #1d2129;
        cursor: pointer;
        transition: box-shadow 1s, border 1s;
        /* 定义过渡属性和持续时间 */

        .pay-logo {
          width: 36px;
          height: 36px;
          margin-right: 16px;
        }
      }
    }
  }
}
.right-middle {
  display: flex;
  margin-top: 15px;
}
/deep/ .el-dialog__body {
  padding: 0px 40px 30px 40px !important;
}
.refresh2 {
  width: 180px;
  height: 32px;
  background: #6647ff;
  border-radius: 2px;
  color: white;
  line-height: 32px;
  text-align: center;
  margin-bottom: 86px;
  cursor: pointer;
}
</style>
