<template>
  <div v-if="roomDialog">
    <el-dialog :visible.sync="dialog" width="880px" center @close="closedialog">
      <template slot="title">
        <div class="title_div">
          <img class="logo" src="@/assets/logosss.png" />
          <div class="words">直播间席位购买</div>
          <img class="bgclogo" src="@/assets/bgclogo.png" />
        </div>
      </template>
      <div class="body" v-if="flag == 0" v-loading="isLoading">
        <div class="left">
          <div class="left-title">
            <img src="@/assets/towes.png" class="title-logo" />
            <div class="title-words">直播间席位</div>
          </div>
          <div class="left-main">
            <div class="left-items">
              <img src="@/assets/checks.png" class="left-imgs" />
              <div class="left-words">新增席位可用所有功能</div>
            </div>
            <div class="left-items">
              <img src="@/assets/checks.png" class="left-imgs" />
              <div class="left-words">增购一个席位1.98元/日</div>
            </div>
            <div class="left-items">
              <img src="@/assets/checks.png" class="left-imgs" />
              <div class="left-words">时间越长越优惠</div>
            </div>
            <!-- <div class="left-items">
              <img src="@/assets/checks.png" class="left-imgs" />
              <div class="left-words">时长大于90天八折</div>
            </div>
            <div class="left-items">
              <img src="@/assets/checks.png" class="left-imgs" />
              <div class="left-words">时长大于180天七五折</div>
            </div> -->
            <div class="left-items">
              <img src="@/assets/checks.png" class="left-imgs" />
              <div class="left-words">根据当前服务剩余时间按天购买</div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="right-top">
            <div class="right-title">
              席位数量&nbsp;&nbsp;&nbsp;
              <span style="font-size: 12px; color: #909399">
                时长剩余
                <span style="color: #9f7dff">{{ days }}</span>
                天
              </span>
            </div>
            <div>
              增加席位&nbsp;&nbsp;&nbsp;
              <el-input-number
                v-model="num"
                @change="getPrice"
                :min="1"
                label="描述文字"
                size="mini"
              ></el-input-number>
              &nbsp;&nbsp;&nbsp;
              <span style="font-size: 12px; color: #909399">
                增加后席位总数
                <span style="color: #9f7dff">{{ addRoom }}</span>
              </span>
            </div>
          </div>
          <div class="right-mid">
            <div class="right-title">付款金额</div>
            <div style="display: flex">
              <span style="font-weight: 500; font-size: 16px; color: #f53f3f; margin-top: 7px">
                ￥
              </span>
              <span style="font-weight: 500; font-size: 28px; color: #f53f3f">
                {{ totalAmount / 100 }}
              </span>
              <el-tooltip class="item" effect="dark" placement="right">
                <div slot="content">
                  <div style="font-size: 16px">订单明细</div>
                  <div>
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        margin-top: 5px;
                      "
                    >
                      <div>席位单价</div>
                      <div>{{ liveRoomPrice / 100 }}元/日</div>
                    </div>
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        margin-top: 5px;
                      "
                    >
                      <div>增购数量</div>
                      <div>{{ num }}</div>
                    </div>
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        margin-top: 5px;
                        margin-bottom: 5px;
                      "
                    >
                      <div>增购天数</div>
                      <div>{{ days }}</div>
                    </div>
                  </div>
                  <div>付费总额=单价*数量*天数={{ totalAmount / 100 }}元</div>
                </div>
                <img
                  src="@/assets/wenhao.png"
                  style="width: 16px; height: 16px; margin-left: 5px; margin-top: 11px"
                />
              </el-tooltip>
            </div>
          </div>
          <div class="right-bottom">
            <div class="right-title">支付方式</div>
            <div class="pay-list">
              <div class="pay-item" @click="toPay(1)">
                <img src="@/assets/wxLogos.png" class="pay-logo" />
                <div>微信扫码支付</div>
              </div>
              <div class="pay-item" @click="toPay(2)">
                <img src="@/assets/zfbLogos.png" class="pay-logo" />
                <div>支付宝支付</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="body2" v-if="flag == 1">
        <div class="boxss">
          <img class="body-img" src="@/assets/greenGou.png" />
          <div class="paying">已完成支付</div>
          <div class="refresh" @click="refresh">已支付，点击刷新</div>
        </div>
        <div style="font-weight: 400; font-size: 14px; color: #606266">
          未完成支付？
          <span style="color: #6647ff; cursor: pointer" @click="init">重新选择</span>
        </div>
      </div>
    </el-dialog>

    <!-- 微信支付弹窗 -->
    <el-dialog class="payDialog" :visible.sync="wxDialogVisible" width="360px" center>
      <div class="wx-pay-dialog">
        <div class="title">微信支付</div>
        <div class="tip">请使用微信-扫一扫-进行支付</div>
        <div class="qrcode">
          <div
            id="qrcodes"
            ref="qrCodeContainer"
            style="width: 160px; height: 160px; margin-bottom: 20px"
          ></div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import user from '@/api/user'
import { mapState } from 'vuex'
import ajax from '@/libs/request'
import QRCode from 'qrcodejs2'

export default {
  name: 'buyLocal',
  data() {
    return {
      dialog: false,
      flag: 0,
      wxDialogVisible: false,
      wxQrCode: '直播中控宝',
      timer: null,
      isLoading: false,
      count: 120,
      num: 1,
      liveRoomNum: 1,
      days: 0,
      liveRoomPrice: 0,
      totalAmount: 0
    }
  },
  computed: {
    ...mapState(['roomDialog']),
    addRoom() {
      return this.liveRoomNum + this.num
    }
  },
  methods: {
    refresh() {
      setTimeout(() => {
        location.reload()
      }, 1000)
    },
    closedialog() {
      if (this.flag == 1) {
        this.refresh()
      } else {
        this.dialog = false
        this.$store.commit('setRoomDialog', false)
      }
    },
    init() {
      this.flag = 0
      this.isLoading = false
      this.timer = null
      this.count = 120
      this.num = 1
      this.getBuyingAccountList()
      this.getPrice()
    },
    startCount(val) {
      //进入缓存状态，判断成功或失败
      this.isLoading = true
      clearInterval(this.timer)
      this.timer = setInterval(async () => {
        if (this.count == 0) {
          //时间耗尽
          clearInterval(this.timer)
          this.isLoading = false
        } else {
          const res = await user.getOrderStatusForRoom({ orderNo: val })
          if (res.code == 0) {
            if (res.data.payStatus == 1) {
              //支付成功
              clearInterval(this.timer)
              this.count = 120
              this.isLoading = false
              this.flag = 1
              this.wxDialogVisible = false
            } else if (res.data.payStatus == 3) {
              //支付失败
              clearInterval(this.timer)
              this.count = 120
              this.isLoading = false
              this.wxDialogVisible = false
              this.$message.warning(res.msg)
            } else {
              this.count--
            }
          } else {
            //请求出错
            clearInterval(this.timer)
            this.isLoading = false
            this.wxDialogVisible = false
            this.$message.warning(res.msg)
            this.count = 120
          }
        }
      }, 2000)
    },
    async toPay(type) {
      if (type == 2) {
        //支付宝支付
        const res = await user.createLiveRoomPayOrder({
          companyCode: localStorage.getItem('companyCode'),
          liveRoomNum: this.num,
          payType: 1
        })
        if (res.code == 0) {
          if (res.data) {
            const passbackParams = {
              orderNo: res.data.orderNo,
              omgOrderNo: res.data.orderNo,
              serverNo: 10
            }
            const afterParams = encodeURIComponent(JSON.stringify(passbackParams))
            const url = `${res.data.payUrl}?totalAmount=${res.data.totalAmountYuan}&subject=${res.data.subject}&passbackParams=${afterParams}`
            window.open(url)
            this.startCount(res.data.orderNo)
          } else {
            this.$message.error('无数据返回')
          }
        } else {
          this.$message.error(res.msg)
        }
      } else {
        const res = await user.createLiveRoomPayOrder({
          companyCode: localStorage.getItem('companyCode'),
          liveRoomNum: this.num,
          payType: 2
        })
        if (res.code == 0) {
          if (res.data) {
            const passbackParams = {
              orderNo: res.data.orderNo,
              omgOrderNo: res.data.orderNo,
              serverNo: 10
            }
            const afterParams = JSON.stringify(passbackParams)
            const getData = () => {
              return ajax.get(res.data.payUrl, {
                params: {
                  total_fee: res.data.totalAmount,
                  order_no: res.data.orderNo,
                  title: res.data.subject,
                  attach: afterParams
                }
              })
            }
            getData().then(res2 => {
              this.wxDialogVisible = true
              console.log(res2.data)
              setTimeout(() => {
                document.getElementById('qrcodes').innerHTML = ''
                new QRCode('qrcodes', {
                  width: 160, // 二维码宽度
                  height: 160, // 二维码高度
                  text: res2.data, // 浏览器地址url
                  colorDark: '#000000',
                  colorLight: '#ffffff',
                  correctLevel: QRCode.CorrectLevel.H
                })
                this.startCount(res.data.orderNo)
              }, 20)
            })
          }
        }
      }
    },
    async getBuyingAccountList() {
      const res = await user.getComapnyLiveRoomInfoByCompanyCode({
        companyCode: localStorage.getItem('companyCode')
      })
      if (res.code == 0) {
        this.liveRoomNum = res.data.liveRoomNumCount
      }
    },
    getPrice() {
      user
        .getLiveRoomPayInfo({
          companyCode: localStorage.getItem('companyCode'),
          liveRoomNum: this.num
        })
        .then(res => {
          if (res.code == 0) {
            this.totalAmount = res.data.totalAmount
            this.liveRoomPrice = res.data.liveRoomPrice
            this.days = res.data.days
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  },
  watch: {
    roomDialog: {
      handler: function (val) {
        // console.log(val)
        if (val) {
          this.init()
          this.dialog = true
        }
      },
      immediate: true
    },
    dialog: {
      handler: function (val) {
        if (!val) {
          clearInterval(this.timer)
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep {
  .el-dialog__header {
    padding: 0 !important;
  }
}

.title_div {
  width: 100%;
  height: 80px;
  background: linear-gradient(135deg, #9f7dff 0%, #6647ff 100%);
  display: flex;
  align-items: center;
  padding-left: 16px;
  box-sizing: border-box;
  position: relative;

  .logo {
    width: 48px;
    height: 48px;
  }

  .words {
    margin-left: 12px;
    font-weight: 500;
    font-size: 18px;
    color: #ffffff;
  }

  .bgclogo {
    position: absolute;
    width: 200px;
    height: 80px;
    right: 67px;
    top: 0;
  }
}

::v-deep .el-dialog__close::before {
  color: white;
}

.body {
  display: flex;
  justify-content: space-between;
}

.body2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .body-img {
    width: 80px;
    height: 80px;
  }

  .boxss {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .paying {
    width: 90px;
    height: 26px;
    font-weight: 500;
    font-size: 18px;
    color: #303133;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .refresh {
    width: 144px;
    height: 32px;
    background: #6647ff;
    border-radius: 2px;
    color: white;
    line-height: 32px;
    text-align: center;
    margin-bottom: 86px;
    cursor: pointer;
  }
}

::v-deep .el-dialog__body {
  background: linear-gradient(135deg, #fbfaff 0%, #f1efff 100%);
  height: 380px;
  border-radius: 8px;
  box-sizing: border-box;
}

.left {
  width: 220px;
  height: 356px;

  .left-title {
    width: 114px;
    height: 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .title-logo {
      width: 20px;
      height: 20px;
    }

    .title-words {
      /* 创建渐变 */
      background: linear-gradient(45deg, #9f7dff 0%, #6647ff 100%);
      /* 设置背景为渐变，并添加背景文字 */
      -webkit-background-clip: text;
      background-clip: text;
      /* 设置文字颜色为透明，使得渐变背景能够显示出来 */
      color: transparent;
      font-weight: 500;
      font-size: 18px;
    }
  }

  .left-main {
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .left-items {
      height: 20px;
      display: flex;
      align-items: center;

      .left-imgs {
        width: 16px;
        height: 16px;
        margin-right: 7px;
      }

      .left-words {
        font-size: 14px;
        color: #606266;
      }
    }
  }
}

.right {
  width: 558px;

  .right-title {
    font-weight: 500;
    font-size: 18px;
    color: #303133;
    margin-bottom: 20px;
  }

  .right-chooses {
    width: 558px;
    height: 152px;
    display: flex;
    justify-content: space-between;
    padding-top: 12px;

    .right-item:hover {
      background: linear-gradient(135deg, #ffffff 0%, #eae4ff 100%);
      box-shadow: 0px 2px 12px 0px rgba(54, 55, 59, 0.1);
      border-radius: 8px;
      border: 2px solid #6647ff;
      box-sizing: border-box;
    }

    .item-select {
      background: linear-gradient(135deg, #ffffff 0%, #eae4ff 100%);
      box-shadow: 0px 2px 12px 0px rgba(54, 55, 59, 0.1);
      border-radius: 8px;
      border: 2px solid #6647ff;
      box-sizing: border-box;
    }

    .right-item {
      width: 130px;
      height: 140px;
      background: #ffffff;
      border-radius: 8px;
      padding-left: 14px;
      box-sizing: border-box;
      position: relative;
      cursor: pointer;

      .item-year {
        margin-top: 24px;
        font-size: 16px;
        color: #1d2129;
        font-weight: 500;
      }

      .item-price {
        color: #6647ff;
        font-size: 24px;
        margin-top: 8px;
        margin-bottom: 12px;
      }

      .item-additional {
        border-radius: 4px;
        border: 1px solid #6647ff;
        padding: 1px 8px;
        width: max-content;
        font-weight: 500;
        font-size: 12px;
        color: #6647ff;
      }

      .item-sup {
        position: absolute;
        background: linear-gradient(135deg, #ff8f8f 0%, #ff4444 100%);
        border-radius: 12px 4px 12px 0px;
        padding: 3px 12px;
        left: 0;
        top: -12px;
        color: #ffffff;
        font-size: 12px;
      }
    }
  }

  .right-bottom {
    margin-top: 20px;

    .pay-list {
      display: flex;
      justify-content: space-between;

      .pay-item {
        width: 273px;
        height: 84px;
        background: #ffffff;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 18px;
        color: #1d2129;
        cursor: pointer;
        transition: box-shadow 1s, border 1s;
        /* 定义过渡属性和持续时间 */

        .pay-logo {
          width: 36px;
          height: 36px;
          margin-right: 16px;
        }
      }
    }
  }
}

.pay-item:hover {
  box-shadow: 0px 2px 12px 0px rgba(54, 55, 59, 0.1);
}

.wx-pay-dialog {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    position: relative;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    color: black;
  }

  .tip {
    position: relative;
    line-height: 36px;
  }
}

::v-deep .payDialog .el-dialog__body {
  height: 300px !important;
}
.right-mid {
  margin-top: 20px;
}
</style>
