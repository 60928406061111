var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-wrapper"},[_vm._l((_vm.routes),function(item){return [(
          !item.hidden &&
          item.children &&
          _vm.getState(item.useTypeHidden) &&
          _vm.getVersionState(item.versionTypeHidden) &&
          _vm.isBdsh(item.bdshHidden) &&
          _vm.isClientHidden(item.isClientHidden) &&
          _vm.isXhs(item.xhsHidden)
        )?[(
            _vm.hasOneShowingChildren(item.children) &&
            !item.children[0].children &&
            _vm.getState(item.useTypeHidden) &&
            _vm.getVersionState(item.versionTypeHidden)
          )?_c('router-link',{key:item.children[0].name,attrs:{"to":item.path + '/' + item.children[0].path}},[_c('el-menu-item',{staticStyle:{"position":"relative"},attrs:{"index":item.path + '/' + item.children[0].path}},[_c('div',{staticClass:"title_item"},[(item.children[0].meta && item.children[0].meta.icon)?_c('img',{attrs:{"src":item.children[0].meta.icon}}):_vm._e(),(item.children[0].meta && item.children[0].meta.title)?_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(item.children[0].meta.title)+" ")]):_vm._e()])])],1):_c('el-submenu',{key:item.name,attrs:{"index":item.name || item.path}},[_c('template',{slot:"title"},[_c('div',{staticClass:"title_item"},[(item.children[0].meta && item.children[0].meta.icon)?_c('img',{attrs:{"src":item.children[0].meta.icon}}):_vm._e(),(item.meta && item.meta.title)?_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(item.meta.title))]):_vm._e()])]),_vm._l((item.children),function(child){return [(
                !child.hidden &&
                _vm.getState(child.useTypeHidden) &&
                _vm.getVersionState(item.versionTypeHidden) &&
                _vm.isKS(child.dyHidden)
              )?[(child.children && child.children.length > 0)?_c('sidebar-item',{key:child.path,staticClass:"nest-menu",attrs:{"is-nest":true,"routes":[child]}}):_c('router-link',{key:child.name,attrs:{"to":item.path + '/' + child.path}},[_c('el-menu-item',{staticClass:"test",staticStyle:{"position":"relative"},attrs:{"index":item.path + '/' + child.path}},[_c('div',{staticClass:"title_item",style:(_vm.isVersion(child) ? 'opacity:0.4' : '')},[(child.meta && child.meta.title)?_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(child.meta.title)+" ")]):_vm._e()]),(_vm.isVersion(child))?_c('img',{staticClass:"logosimg",attrs:{"src":require("../assets/per.png"),"alt":""}}):_vm._e()])],1)]:_vm._e()]})],2)]:_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }